.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

// Display classes
.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.block {
    display: block;
}

// Position classes
.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

// Scrollable
.scrollable {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;

    &.hide-x {
        overflow-x: hidden;
    }

    &.hide-y {
        overflow-y: hidden;
    }
}
