@use 'sass:list';
@use 'sass:map';

$breakpoints: (
    xs: 0,
    sm: 600px,
    md: 960px,
    lg: 1280px,
    xl: 1920px,
);
$breakpoint-keys: map.keys($breakpoints);
$breakpoint-unit: px;
$breakpoint-step: 0.05;

@function breakpoint($breakpointName: xs) {
    @return map.get($breakpoints, $breakpointName);
}

@mixin mq-up($breakpoint, $show-warning: true) {
    @if map.has-key($breakpoints, $breakpoint) {
        $index: list.index($breakpoint-keys, $breakpoint);

        @if $index > 1 {
            @media (min-width: map.get($breakpoints, $breakpoint)) {
                @content;
            }
        } @else {
            @content;

            // stylelint-disable-next-line max-nesting-depth
            @if $show-warning {
                @warn 'Using #{$breakpoint} breakpoint as mq-up is redundant. Remove the media query.';
            }
        }
    } @else {
        @error 'Given breakpoint #{$breakpoint} is missing.';
    }
}

@mixin mq-down($breakpoint) {
    @if map.has-key($breakpoints, $breakpoint) {
        $upper-index: list.index($breakpoint-keys, $breakpoint) + 1;

        @if $upper-index == list.length($breakpoint-keys) + 1 {
            $lowest-breakpoint-key: list.nth($breakpoint-keys, 1);

            /* stylelint-disable-next-line max-line-length */
            @warn 'Using #{$breakpoint} breakpoint as mq-down is redundant. Use mq-up(#{$lowest-breakpoint-key}) instead.';

            @include mq-up($lowest-breakpoint-key, false) {
                @content;
            }
        } @else {
            $upper-breakpoint-key: list.nth($breakpoint-keys, $upper-index);

            @media (max-width: map.get($breakpoints, $upper-breakpoint-key) - $breakpoint-step) {
                @content;
            }
        }
    } @else {
        @error 'Given breakpoint #{$breakpoint} is missing.';
    }
}

@mixin mq-between($start-breakpoint, $end-breakpoint, $show-warning: true) {
    @if map.has-key($breakpoints, $start-breakpoint) and map.has-key($breakpoints, $end-breakpoint) {
        $start-index: list.index($breakpoint-keys, $start-breakpoint);
        $end-index: list.index($breakpoint-keys, $end-breakpoint);

        @if $end-index == list.length($breakpoint-keys) {
            @if $show-warning {
                /* stylelint-disable-next-line max-line-length */
                @warn 'Using #{$end-breakpoint} breakpoint as mq-betwen end is redundant. Use mq-up(#{$start-breakpoint}) instead.';
            }

            @include mq-up($start-breakpoint) {
                @content;
            }
        } @else {
            @if $start-index == $end-index {
                @if $show-warning {
                    @warn 'Start and end breakpoints are the same. Use mq-only(#{$start-breakpoint}) instead.';
                }
            } @else if $start-index > $end-index {
                @if $show-warning {
                    /* stylelint-disable-next-line max-line-length */
                    @warn 'Start breakpoint (#{$start-breakpoint}) is bigger than end breakpoint (#{$end-breakpoint}). Remove it or change it.';
                }
            }

            $upper-breakpoint: list.nth($breakpoint-keys, $end-index + 1);

            @media
                (min-width: map.get($breakpoints, $start-breakpoint)) and
                (max-width: map.get($breakpoints, $upper-breakpoint) - $breakpoint-step) {
                @content;
            }
        }
    } @else {
        @error 'Both or one of the given breakpoints (#{$start-breakpoint}, #{$end-breakpoint}) is missing.';
    }
}

@mixin mq-only($breakpoint) {
    @if map.has-key($breakpoints, $breakpoint) {
        $index: list.index($breakpoint-keys, $breakpoint);

        @if $index == list.length($breakpoint-keys) {
            /* stylelint-disable-next-line max-line-length */
            @warn 'Using #{$breakpoint} breakpoint as mq-only parameter is redundant. Use mq-up(#{$breakpoint}) instead.';

            @include mq-up($breakpoint) {
                @content;
            }
        } @else {
            @include mq-between($breakpoint, $breakpoint, false) {
                @content;
            }
        }
    } @else {
        @error 'Given breakpoint #{$breakpoint} is missing.';
    }
}

@mixin mq($condition, $breakpoint-one, $breakpoint-two: null) {
    @if ($condition == up) {
        @include mq-up($breakpoint-one) {
            @content;
        }
    } @else if ($condition == down) {
        @include mq-down($breakpoint-one) {
            @content;
        }
    } @else if ($condition == between) {
        @include mq-between($breakpoint-one, $breakpoint-two) {
            @content;
        }
    } @else if ($condition == only) {
        @include mq-only($breakpoint-one) {
            @content;
        }
    }
}
