@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.wave {
    align-items: center;
    column-gap: theme.spacing(0.25);
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    width: fit-content;

    &.center {
        width: 100%;
    }

    .dot {
        animation: wave-frame 1s linear infinite;
        border-radius: 50%;
        height: 5px;
        width: 5px;

        &:nth-child(2) {
            animation-delay: 0.1s;
        }

        &:nth-child(3) {
            animation-delay: 0.2s;
        }

        &.ocean {
            background-color: theme.color(ocean-400);
        }

        &.space {
            background-color: theme.color(space-400);
        }
    }
}

@keyframes wave-frame {
    0%,
    100% {
        transform: initial;
    }

    20% {
        transform: translateY(-100%);
    }

    50% {
        transform: translateY(60%);
    }

    80% {
        transform: translateY(-20%);
    }
}
