@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-compound-selectors */
.root {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    user-select: none;
    vertical-align: middle;

    &.disabled {
        cursor: default;

        .iconWrapper {
            background-color: theme.color(snow-100);
            border-color: theme.color(gray-100);
        }

        .input:checked ~ .iconWrapper,
        .input:indeterminate ~ .iconWrapper {
            background-color: theme.color(ocean-200);
            border-color: theme.color(ocean-200);
        }

        &:hover .iconWrapper {
            border-color: theme.color(gray-100);
        }

        &:hover .input:checked ~ .iconWrapper {
            background-color: theme.color(ocean-200);
            border-color: theme.color(ocean-200);
        }

        .labelText {
            color: theme.color(space-300) !important;
        }

        .requiredIndicator {
            color: theme.color(space-300) !important;
        }
    }

    &:hover .iconWrapper {
        border-color: theme.color(ocean-300);
    }

    &:hover .input:checked ~ .iconWrapper {
        background-color: theme.color(ocean-400);
        border-color: theme.color(ocean-400);
    }

    .input {
        appearance: none;
        background-color: transparent;
        opacity: 0;
    }

    .input:checked ~ .iconWrapper,
    .input:indeterminate ~ .iconWrapper {
        background-color: theme.color(ocean-500);
        border-color: theme.color(ocean-500);

        .icon {
            color: theme.color(snow-100);
        }
    }

    .input:checked ~ .labelText {
        color: theme.color(space-500);
    }

    .iconWrapper {
        align-items: center;
        background-color: theme.color(snow-50);
        border: 2px solid theme.color(gray-200);
        border-radius: theme.get-border-radius(sm);
        display: flex;
        height: 14px;
        justify-content: center;
        width: 14px;

        &.error {
            border-color: theme.color(red-400) !important;
        }
    }

    .icon {
        color: transparent;
        font-size: 8px;
    }

    .labelText {
        @include theme.typo(body3);

        color: theme.color(space-500);
        margin-left: theme.spacing(0.5);
    }

    .requiredIndicator {
        @include theme.typo(body3);

        color: theme.color(red-500);
        margin-left: theme.spacing(0.25);
    }
}
