@use '~$app-web/ui-kit/styles/theme';

.choice {
    margin: theme.spacing(1);
    width: 100%;
}

.imageRadioGroup {
    justify-content: center;
}

.imageChoice {
    align-self: flex-start;
}
