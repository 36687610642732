@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    @include theme.reset-button();
    @include theme.typo(body3);

    align-items: center;
    cursor: default;
    display: flex;
    height: 100%;

    &.disabled {
        cursor: default;
        pointer-events: none;
    }

    &.clickable {
        cursor: pointer;
    }

    &.startAdornment {
        padding-left: theme.spacing(1);
    }

    &.endAdornment {
        padding-right: theme.spacing(1);
    }
}
