@use 'sass:list';
@use 'sass:map';
@use '../theme_old';

$heading-variants: h1, h2, h3, h4, h5, h6;

@each $variant in map-keys(theme_old.$typography-variants) {
    .typo-old.#{$variant} {
        @include theme_old.typo-old($variant);
    }

    @if list.index($heading-variants, $variant) != false {
        #{$variant}.typo-old {
            @include theme_old.typo-old($variant);
        }
    }
}

.typo-old {
    font-family: theme_old.$typography-font-family !important;

    @each $weight in map.keys(theme_old.$typography-font-weights) {
        &.weight-#{$weight} {
            @include theme_old.font-weight($weight, true);
        }
    }

    &.nowrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.paragraph {
        margin-bottom: theme_old.spacing(2);
    }

    &.gutter-bottom {
        margin-bottom: 0.35em;
    }
}

// Text Alignment
$text-align-list: left, right, center, justify;

@each $align in $text-align-list {
    .text-#{$align} {
        text-align: $align;
    }
}
