@use 'sass:map';
@use 'sass:math';
@use '../theme';

$spacing: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
$item-sizes: auto, fill, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

/* TODO: The way I used displays here is not the best approach;
 * it'll simply fail for something like `flex item xs-0 md-1`.
 * It's just a temporary solution.
*/
@mixin generate-row-item-sizes($breakpoint) {
    &.#{$breakpoint} {
        display: initial;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;

        &.flex {
            display: flex;
        }
    }

    @each $size in $item-sizes {
        &.#{$breakpoint}-#{$size} {
            @if $size == auto {
                display: initial;
                flex-basis: auto;
                flex-grow: 0;
                max-width: none;

                &.flex {
                    display: flex;
                }
            } @else if $size == fill {
                display: initial;
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;

                &.flex {
                    display: flex;
                }
            } @else if $size == 0 {
                display: none;

                &.flex {
                    display: none;
                }
            } @else {
                $width: math.div(math.round(math.div($size, 12) * math.pow(10, 7)), math.pow(10, 5)) * 1%;

                display: initial;
                flex-basis: $width;
                flex-grow: 0;
                max-width: $width;

                &.flex {
                    display: flex;
                }
            }
        }
    }
}

.flex {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &.inline {
        display: inline-flex;
        width: auto;
    }

    &.auto-width {
        width: auto;
    }

    &.row {
        flex-direction: row;
    }

    &.column {
        flex-direction: column;
        height: 100%;
        width: auto;

        &-reverse {
            flex-direction: column-reverse;
        }
    }

    &.reverse {
        flex-direction: row-reverse;
    }

    &.nowrap {
        flex-wrap: nowrap;
    }

    &.wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    &.items-start {
        align-items: flex-start;
    }

    &.items-end {
        align-items: flex-end;
    }

    &.items-center {
        align-items: center;
    }

    &.items-baseline {
        align-items: baseline;
    }

    &.items-stretch {
        align-items: stretch;
    }

    &.content-start {
        align-content: flex-start;
    }

    &.content-end {
        align-content: flex-end;
    }

    &.content-center {
        align-content: center;
    }

    &.content-between {
        align-content: space-between;
    }

    &.content-around {
        align-content: space-around;
    }

    &.content-evenly {
        align-content: space-evenly;
    }

    &.justify-start {
        justify-content: flex-start;
    }

    &.justify-end {
        justify-content: flex-end;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-between {
        justify-content: space-between;
    }

    &.justify-around {
        justify-content: space-around;
    }

    &.justify-evenly {
        justify-content: space-evenly;
    }

    @each $space in $spacing {
        $offset: math.div(theme.$base-space * $space, 2);
        &.space-#{$space} {
            margin: $offset * -1;
            width: calc(100% + #{2 * $offset});

            > .item {
                padding: $offset;
            }
        }
    }

    > .item {
        box-sizing: border-box;

        &.zero-min-width {
            min-width: 0 !important;
        }

        // stylelint-disable max-nesting-depth
        @each $breakpoint in map.keys(theme.$breakpoints) {
            @if $breakpoint == xs {
                @include generate-row-item-sizes($breakpoint);
            } @else {
                @include theme.mq-up($breakpoint) {
                    @include generate-row-item-sizes($breakpoint);
                }
            }
        }
    }

    &.column > .item {
        @each $size in $item-sizes {
            &.size-#{$size} {
                @if $size == auto {
                    flex-basis: auto;
                    flex-grow: 0;
                    max-height: none;
                } @else if $size == fill {
                    flex-basis: 0;
                    flex-grow: 1;
                    max-height: 100%;
                } @else {
                    $height: math.div(math.round(math.div($size, 12) * math.pow(10, 7)), math.pow(10, 5)) * 1%;

                    flex-basis: $height;
                    flex-grow: 0;
                    max-height: $height;
                }
            }
        }
    }
}
