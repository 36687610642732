@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    position: relative;
    width: 100%;

    &.hidden {
        visibility: hidden;
    }

    .displayNone {
        display: none !important;
    }

    .chipsWrapper {
        display: flex;
        flex-wrap: nowrap;
    }

    &:not(.truncate) .chipsWrapper {
        flex-wrap: wrap;
        gap: theme.spacing(0.5);
    }

    &.truncate .chipWrapper {
        max-width: 115px;

        &:not(:last-child, .extraChipsCounter) {
            margin-right: theme.spacing(0.5);
        }
    }

    &.absolutePositioned {
        left: 0;
        margin-left: theme.spacing(0.75);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .extraChipsCounter {
        @include theme.typo(body3);

        align-items: center;
        color: theme.color(space-500);
        display: flex;
        margin-left: theme.spacing(0.5);
    }
}
