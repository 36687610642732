@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    min-width: 70px;
    width: 200px;

    [data-radix-popper-content-wrapper] {
        min-width: inherit !important;
        width: inherit;
    }

    .chevronMargin {
        margin-left: theme.spacing(1);
    }

    .fixChevronIconHeight {
        margin-bottom: theme.spacing(-1);
        margin-top: theme.spacing(-1);
    }

    .headerWrapper {
        position: relative;
    }

    .selectBoxHeader {
        @include theme.typo(body3);

        background-color: theme.color(snow-50);
        border: 2px solid theme.color(gray-100);
        border-radius: theme.get-border-radius(md);
        color: theme.color(space-500);
        cursor: pointer;
        height: 40px;
        min-width: inherit;
        padding: theme.spacing(1);
        width: inherit;

        .selectBoxHeaderText {
            @include theme.truncate();

            &.placeholder {
                color: theme.color(space-300);
            }
        }

        &.disabledSelectBoxHeader {
            background-color: theme.color(snow-100);
            color: theme.color(space-200);
            cursor: default;
        }

        &:focus,
        &.active {
            border-color: theme.color(ocean-400);
        }
    }

    &.error {
        .selectBoxHeader {
            border-color: theme.color(red-500);
        }

        .error {
            @include theme.typo(info2);

            color: theme.color(red-400);
            margin-left: theme.spacing(0.25);
        }
    }

    &.variantHeaderBold {
        .selectBoxHeader {
            @include theme.typo(h4);
        }
    }

    &.variantHeaderBase {
        .selectBoxHeader {
            border: none;
            color: theme.color(space-500);
            height: 32px;
            padding: theme.spacing(0.5, 1);

            &:focus,
            &.active {
                background-color: theme.color(snow-100);
            }
        }
    }

    .errorMessageWrapper {
        margin-bottom: theme.spacing(1);
        margin-top: theme.spacing(0.25);

        .error {
            display: inline-block;
        }
    }

    &.chips {
        // TODO: consider handling width of the component in a more standard way (when it's not fullWidth)
        width: 300px;
    }

    &.fullWidth {
        position: relative;
        width: 100%;

        [data-radix-popper-content-wrapper] {
            // TODO: implement a solution to adjust the width of the popup considering its usage inside dialogs
            width: max-content;
        }
    }
}
