@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    background-color: theme.color(snow-50);
    border-radius: theme.get-border-radius(md);
    box-shadow: theme.box-shadow(lg);
    padding: theme.spacing(0.5);
    width: 100%;
}
