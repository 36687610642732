@use 'sass:math';
@use 'sass:meta';
@use './colors';

@function if-important($important) {
    @return #{if($important, '!important', '')};
}

@function strip-unit($value) {
    @if meta.type-of($value) == 'number' and not math.is-unitless($value) {
        @return math.div($value, ($value * 0 + 1));
    }

    @return $value;
}

@function is-map($variable) {
    @return meta.type-of($variable) == 'map';
}

@function is-string($variable) {
    @return meta.type-of($variable) == 'string';
}

// Reset the button to behave like div
@mixin reset-button() {
    appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    margin: 0;
    outline: 0;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;

    &::-moz-focus-inner {
        border-style: none;
    }
}

@mixin scrollbar() {
    // The new standard syntax (Currently only supported in Firefox)
    scrollbar-color: colors.color(gray-200) colors.color(snow-50);
    scrollbar-width: thin;

    // For Chrome, Edge, Safari, and Opera
    ::-webkit-scrollbar {
        height: 7px;
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: colors.color(gray-200);
        border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: colors.color(gray-200);
    }
}
