@use 'sass:list';
@use 'sass:map';
@use '../theme';

.new-typography-wrapper {
    $heading-variants: h1, h2, h3, h4, h5;
    $text-align-list: start, end, left, right, center, justify;

    * {
        font-family: theme.$typography-font-family;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
    }

    a {
        color: theme.color(ocean-500);

        &:hover {
            color: theme.color(ocean-400);
        }
    }

    @each $align in $text-align-list {
        .text-#{$align} {
            text-align: $align;
        }
    }
    @each $variant in map-keys(theme.$typography-variants) {
        .typo.#{$variant} {
            @include theme.typo($variant);
        }

        // Heading elements
        @if list.index($heading-variants, $variant) != false {
            #{$variant} {
                @include theme.typo($variant);
            }
        }
    }
}

.truncate {
    @include theme.truncate();
}
