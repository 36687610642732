@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

/* TODO: We have also set some styles for this component in the "web-app.scss" to achieve a quick fix
 * Please refer to that file for more details.
*/
.contentWrapper {
    @include theme.typo(info3);

    background-color: theme.color(space-300);
    border-radius: theme.get-border-radius(md);
    box-shadow: theme.box-shadow(md);
    color: theme.color(snow-50);

    // TODO: this should be removed when the tooltip fixed the size problem according to the new design
    max-width: 500px;
    padding: theme.spacing(1);
    white-space: pre-line;
}

.lightContentWrapper {
    background-color: theme.color(snow-50);
    color: theme.color(space-400);
}

.arrow {
    fill: theme.color(space-300);
}

.lightArrow {
    fill: theme.color(snow-50);
}
