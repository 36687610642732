@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    .itemsWrapper {
        max-height: 260px;
        overflow-x: hidden;
        overflow-y: auto;

        &.fullHeight {
            max-height: unset;
        }
    }

    .indented {
        margin-left: theme.spacing(1.75);
    }

    .actionsWrapper {
        padding: theme.spacing(1);
        text-align: end;
    }

    .horizontalSeparator {
        background-color: theme.color(gray-100);
        height: 1px;
    }

    .optionGroupHeader {
        @include theme.typo(info1);

        color: theme.color(gray-400);
        display: inline-block;
        margin-top: theme.spacing(1.25);
        padding: theme.spacing(0, 1.25);
        user-select: none;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .loading {
        justify-content: center;
        padding: theme.spacing(2, 2);

        &.narrowPadding {
            padding-bottom: theme.spacing(1);
            padding-top: theme.spacing(1);
        }

        .loadingText {
            @include theme.typo(body2);

            color: theme.color(space-300);
            padding-right: theme.spacing(1);
        }
    }

    .noOptionsFoundText {
        @include theme.typo(body2);

        color: theme.color(space-300);
        padding: theme.spacing(1.5, 2);
        text-align: center;
    }
}
