/* modern-normalize v2.0.0 | https://github.com/sindresorhus/modern-normalize */

*,
::before,
::after {
    box-sizing: border-box;

    /* Disable touch non-standard gestures and also prevent delay in user actions. */
    touch-action: manipulation;

    /* Reset webkit focus outline */
    &:focus {
        outline: none;
    }
}

html {
    font-family:
        system-ui,
        'Segoe UI',
        Roboto,
        Helvetica,
        Arial,
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji';
    line-height: 1.15;
    tab-size: 4;
    text-size-adjust: 100%;
}

body {
    margin: 0;
}

hr {
    color: inherit;
    height: 0;
}

abbr[title] {
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: bolder;
}

code,
kbd,
samp,
pre {
    font-family:
        ui-monospace,
        SFMono-Regular,
        Consolas,
        'Liberation Mono',
        Menlo,
        monospace;
    font-size: 1em;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

table {
    border-color: inherit;
    text-indent: 0;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
select {
    text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    appearance: button;
}

::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

:-moz-focusring {
    outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
    box-shadow: none;
}

legend {
    padding: 0;
}

progress {
    vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    appearance: textfield;
    outline-offset: -2px;
}

::-webkit-search-decoration {
    appearance: none;
}

::-webkit-file-upload-button {
    appearance: button;
    font: inherit;
}

summary {
    display: list-item;
}

/* Reset webkit autofill appearance */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: transparent !important;
    box-shadow: 0 0 0 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
}
