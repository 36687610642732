@use '~$app-web/ui-kit/styles/theme';

.button {
    margin: theme.spacing(4, 0);
}

.input {
    font-size: 48px !important;
    margin-block: theme.spacing(1);
    max-width: 200px;
    padding: theme.spacing(0.5);
    text-align: center;
    width: 100%;
}

.errorText {
    @include theme.typo(body3);

    color: theme.color(red-400);
    margin-top: theme.spacing(1);
    text-align: center;
}
