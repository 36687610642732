@use '~$app-web/ui-kit/styles/theme';

.roundFrameWrapper {
    margin: theme.spacing(2, 0);
}

.button {
    width: 200px;
}

.secondSlotButton {
    text-transform: 'none';
}
