@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    background-color: theme.color(snow-50);
    cursor: pointer;
    padding: theme.spacing(1.25, 1.5);

    .label {
        @include theme.typo(body3);
        @include theme.truncate();

        color: theme.color(space-500);
    }

    .action {
        margin-left: theme.spacing(1);

        &.notPersistent {
            display: none;
        }
    }

    &:hover .notPersistent {
        display: block;
    }

    &.button {
        &:not(:last-of-type) {
            border-bottom: 1px solid theme.color(gray-100);
        }

        .label {
            color: theme.color(ocean-500);
        }
    }

    .icon {
        // TODO-MAYBE: get rid of these negative margins whenever the width and height of the Icon component get fixed
        margin-bottom: theme.spacing(-0.25);
        margin-top: theme.spacing(-0.25);
    }

    .marginRight {
        margin-right: theme.spacing(0.5);
    }

    .badgeWrapper {
        margin-left: theme.spacing(1);

        // TODO-MAYBE: Should it handled globally? (probably removing width: 100% from our flex class.)
        width: initial;
    }

    .endLabel {
        @include theme.typo(info3);

        color: theme.color(space-300);
        margin-left: theme.spacing(1);
        margin-right: auto;
        white-space: nowrap;

        // TODO-MAYBE: Should it handled globally? (probably removing width: 100% from our flex class.)
        width: initial;
    }

    .dragHandle {
        margin-left: theme.spacing(1);
        pointer-events: all;

        & > * {
            cursor: grab;

            &:hover {
                background-color: initial;
            }
        }
    }

    &:hover {
        background-color: theme.color(ocean-50);
    }

    &.highlightedBackground {
        background-color: theme.color(ocean-100);
    }

    &.disabled {
        cursor: default;

        .label {
            color: theme.color(space-200);
        }

        &.button .label {
            color: theme.color(ocean-300);
        }

        &:hover {
            background-color: theme.color(snow-50);
        }
    }
}
