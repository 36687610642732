@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    .badge {
        @include theme.typo(info2);

        align-items: center;
        border-radius: theme.get-border-radius(xl);
        display: flex;
        justify-content: center;
        min-height: 20px;
        min-width: 20px;
        padding: theme.spacing(0, 0.5);
        position: absolute;
    }

    &.standAlone {
        align-items: flex-start;
        display: inline-flex;
        justify-content: flex-start;

        .badge {
            position: static;
            right: 0;
            top: 0;
            transform: none;
        }
    }

    .dot {
        border-radius: theme.get-border-radius(rounded);
        box-sizing: content-box;
        min-height: 10px;
        min-width: 10px;
        padding: theme.spacing(0);

        &.border {
            border: 2px solid theme.color(snow-50);
        }
    }

    .top {
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
    }

    .bottom {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
    }

    .right {
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
    }

    .left {
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .topRight {
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
    }

    .topLeft {
        left: 0;
        top: 0;
        transform: translate(-50%, -50%);
    }

    .bottomLeft {
        bottom: 0;
        left: 0;
        transform: translate(-50%, 50%);
    }

    .bottomRight {
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
    }
}
