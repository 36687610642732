@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

$chip-margin: 1;

.root {
    @include theme.transition(background-color, shorter);

    align-items: center;
    background-color: theme.color(gray-50);
    border-radius: theme.get-border-radius(pill);
    cursor: default;
    display: inline-flex;
    max-width: 100%;
    padding: theme.spacing(0.5);
    vertical-align: middle;

    &.small {
        padding: theme.spacing(0.25);
    }

    &.margin {
        margin: theme.spacing($chip-margin);
    }

    &.topMargin {
        margin-top: theme.spacing($chip-margin);
    }

    &.bottomMargin {
        margin-bottom: theme.spacing($chip-margin);
    }

    &.rightMargin {
        margin-right: theme.spacing($chip-margin);
    }

    &.leftMargin {
        margin-left: theme.spacing($chip-margin);
    }

    &.verticalMargin {
        margin: theme.spacing($chip-margin, 0);
    }

    &.horizontalMargin {
        margin: theme.spacing(0, $chip-margin);
    }

    &.clickable {
        cursor: pointer;
    }

    &:hover {
        background-color: theme.color(gray-100);
    }

    .iconWrapperButton {
        @include theme.reset-button();

        background-color: theme.color(snow-50);
        border-radius: theme.get-border-radius(rounded);
        flex-shrink: 0;
        height: 20px;
        width: 20px;

        &:focus {
            border: 1px solid theme.color(ocean-400);
        }

        .icon > svg {
            color: theme.color(space-400);
        }
    }

    .label {
        @include theme.typo(body3);
        @include theme.truncate();

        align-self: baseline;
        color: theme.color(space-500);
        margin: theme.spacing(0, 0.75);
    }

    /* TODO-MAYBE: This two colors below (green & red) might change in future.
     * The Figma designs weren't complete at the time of implementing this component.
     * Please check the designs later and update the styles if needed.
    */
    &.green {
        background-color: theme.color(green-50);

        &:hover {
            background-color: theme.color(green-100);
        }

        .iconWrapperButton {
            border: 1px solid theme.color(green-100);

            .icon > svg {
                color: theme.color(green-400);
            }
        }

        .label {
            color: theme.color(green-500);
        }
    }

    &.red {
        background-color: theme.color(orange-50);

        &:hover {
            background-color: theme.color(orange-100);
        }

        .iconWrapperButton {
            border: 1px solid theme.color(orange-100);

            .icon > svg {
                color: theme.color(orange-400);
            }
        }

        .label {
            color: theme.color(orange-500);
        }
    }

    &.disabled {
        background-color: theme.color(snow-100);
        cursor: default;

        .iconWrapperButton {
            border: 1px solid theme.color(snow-50);
            cursor: default;

            .icon > svg {
                color: theme.color(space-200);
            }
        }

        .label {
            color: theme.color(space-300);
        }

        &:hover {
            background-color: theme.color(snow-100);
        }
    }
}
