@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    @include theme.reset-button();

    background-color: theme.color(snow-100);
    border-radius: theme.get-border-radius(md);

    svg {
        color: theme.color(space-300);
    }

    &.circular {
        border-radius: theme.get-border-radius(rounded);
    }

    &.fab {
        position: absolute;

        &.top {
            top: theme.spacing(1);
        }

        &.left {
            left: theme.spacing(1);
        }

        &.right {
            right: theme.spacing(1);
        }

        &.bottom {
            bottom: theme.spacing(1);
        }
    }

    &.primary {
        background-color: theme.color(ocean-500);

        svg {
            color: theme.color(snow-50);
        }
    }

    &.secondary {
        background-color: theme.color(ocean-200);

        svg {
            color: theme.color(ocean-500);
        }
    }

    &.ghost {
        background-color: transparent;

        svg {
            color: theme.color(gray-400);
        }
    }

    &.error {
        background-color: theme.color(snow-50);

        svg {
            color: theme.color(red-500);
        }
    }

    &.formatting {
        background-color: transparent;

        svg {
            color: theme.color(space-500);
        }
    }

    &[disabled] svg,
    &:disabled svg {
        color: theme.color(space-200);
    }

    &.border {
        background-color: theme.color(snow-50);
        border: 1px solid theme.color(gray-100);

        svg {
            color: theme.color(gray-300);
        }
    }

    &:hover {
        background-color: theme.color(snow-200);

        svg {
            color: theme.color(space-300);
        }

        &.primary {
            background-color: theme.color(ocean-400);

            svg {
                color: theme.color(snow-50);
            }
        }

        &.secondary {
            background-color: theme.color(ocean-100);

            svg {
                color: theme.color(ocean-400);
            }
        }

        &.ghost {
            background-color: theme.color(gray-50);

            svg {
                color: theme.color(gray-400);
            }
        }

        &.error {
            background-color: theme.color(snow-50);

            svg {
                color: theme.color(red-500);
            }
        }

        // TODO: The design uses a custom colors for this specific type of IconButton. This is not a good pattern.
        //  Communicate this with the design and use consistent colors instead (remove this block).
        &.border {
            background-color: theme.color(ocean-50);

            svg {
                color: theme.color(gray-500);
            }
        }

        &.formatting {
            background-color: theme.color(gray-50);

            svg {
                color: theme.color(space-500);
            }
        }
    }

    &.selected.formatting {
        background-color: theme.color(gray-50);

        svg {
            color: theme.color(ocean-500);
        }
    }

    &[disabled],
    &:disabled {
        background-color: theme.color(snow-100);
        cursor: none;
        pointer-events: none;

        &.primary {
            background-color: theme.color(gray-50);

            svg {
                color: theme.color(gray-200);
            }
        }

        &.secondary {
            background-color: theme.color(ocean-50);

            svg {
                color: theme.color(ocean-200);
            }
        }

        &.ghost {
            background-color: transparent;

            svg {
                color: theme.color(gray-200);
            }
        }

        &.formatting {
            background-color: transparent;

            svg {
                color: theme.color(space-200);
            }
        }
    }
}
