@use 'sass:map';
@use 'sass:math';
@use 'misc' as *;

$typography-html-font-size: 14px;
$typography-font-size: 14px;
$typography-font-family: 'Inter',
    system-ui,
    -apple-system,
    'Segoe UI',
    'Roboto',
    'Noto Sans',
    'Ubuntu',
    'Cantarell',
    'Helvetica Neue';

$typography-font-weights: (
    regular: 400,
    medium: 500,
    bold: 700
);

$typography-variants: (
    h1: 24px medium 34px -0.47px,
    h2: 20px medium 30px -0.33px,
    h3: 20px regular 30px -0.33px,
    h4: 16px bold 24px -0.18px,
    h5: 16px medium 24px -0.18px,
    body1: 14px bold 20px -0.09px,
    body2: 14px medium 20px -0.09px,
    body3: 14px regular 20px -0.09px,
    info1: 12px bold 18px 0.01px,
    info2: 12px medium 18px 0.01px,
    info3: 12px regular 18px 0.01px,
);

@function font-weight($weight: regular) {
    @return map.get($typography-font-weights, $weight);
}

@function px2rem($value) {
    @return math.div($value, $typography-html-font-size) * math.div($typography-font-size, 14px) * 1rem;
}

@mixin typo-by-values($font-size, $font-weight, $line-height, $letter-spacing) {
    @include font-weight($font-weight);

    font-size: px2rem($font-size);
    letter-spacing: $letter-spacing;
    line-height: $line-height;
}

@mixin font-weight($weight: regular, $important: false) {
    font-weight: font-weight($weight) if-important($important);
}

@mixin typo($variant) {
    $variant-values: map.get($typography-variants, $variant);

    @include typo-by-values($variant-values...);

    // TODO: remove this (if it was redundant) after the new typography replaced the old one
    font-family: $typography-font-family;
}

@mixin truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
