@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    &.bottomMargin {
        margin-bottom: theme.spacing(0.5);
    }

    .label {
        @include theme.typo(body2);

        color: theme.color(space-400);
    }

    &.groupLabel .label {
        @include theme.typo(info1);

        color: theme.color(gray-400);
    }

    .requiredIndicator {
        @include theme.typo(info1);

        color: theme.color(red-500);
        margin-left: theme.spacing(0.25);
    }

    .labelHelperTextIcon {
        margin-left: theme.spacing(0.25);
    }

    .labelWarningIcon {
        margin-left: theme.spacing(0.25);
    }

    &.disabled {
        .label,
        .requiredIndicator {
            color: theme.color(space-200);
        }
    }
}
