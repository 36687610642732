@use 'sass:map';

/* =============================================
=         Design System Color Palette         =
============================================= */
$colors: (
    // ocean
    ocean-50: hsla(210, 100%, 95%, 1),
    ocean-100: hsla(210, 100%, 95%, 1),
    ocean-200: hsla(209, 100%, 85%, 1),
    ocean-300: hsla(209, 100%, 74%, 1),
    ocean-400: hsla(209, 96%, 56%, 1),
    ocean-500: hsla(209, 100%, 44%, 1),
    ocean-600: hsla(213, 93%, 23%, 1),
    // space
    space-50: hsla(0, 0%, 94%, 1),
    space-100: hsla(0, 0%, 89%, 1),
    space-200: hsla(0, 0%, 75%, 1),
    space-300: hsla(0, 0%, 50%, 1),
    space-400: hsla(0, 0%, 38%, 1),
    space-500: hsla(198, 18%, 19%, 1),
    // gray
    gray-50: hsla(200, 27%, 95%, 1),
    gray-100: hsla(200, 21%, 92%, 1),
    gray-200: hsla(201, 18%, 84%, 1),
    gray-300: hsla(201, 18%, 71%, 1),
    gray-400: hsla(200, 18%, 57%, 1),
    gray-500: hsl(199, 17%, 53%, 1),
    // green
    green-50: hsla(164, 85%, 94%, 1),
    green-100: hsla(165, 74%, 87%, 1),
    green-200: hsla(166, 63%, 64%, 1),
    green-300: hsla(166, 49%, 51%, 1),
    green-400: hsla(166, 72%, 39%, 1),
    green-500: hsla(166, 76%, 29%, 1),
    // red
    red-50: hsla(346, 100%, 97%, 1),
    red-100: hsla(346, 76%, 90%, 1),
    red-200: hsla(346, 83%, 84%, 1),
    red-300: hsla(345, 75%, 64%, 1),
    red-400: hsla(345, 75%, 55%, 1),
    red-500: hsla(345, 68%, 43%, 1),
    // yellow
    yellow-50: hsla(48, 100%, 95%, 1),
    yellow-100: hsla(48, 100%, 85%, 1),
    yellow-200: hsla(47, 100%, 75%, 1),
    yellow-300: hsla(43, 100%, 67%, 1),
    yellow-400: hsla(46, 100%, 52%, 1),
    yellow-500: hsla(43, 100%, 50%, 1),
    // orange
    orange-50: hsla(21, 100%, 96%, 1),
    orange-100: hsla(21, 100%, 92%, 1),
    orange-200: hsla(25, 100%, 81%, 1),
    orange-300: hsla(29, 100%, 64%, 1),
    orange-400: hsla(18, 100%, 63%, 1),
    orange-500: hsla(12, 86%, 53%, 1),
    // turquoise
    turquoise-50: transparent, // TODO: update this value when it got defined on the designs
    turquoise-100: hsla(187, 100%, 95%, 1),
    turquoise-200: hsla(188, 100%, 85%, 1),
    turquoise-300: hsla(188, 82%, 71%, 1),
    turquoise-400: hsla(188, 100%, 45%, 1),
    turquoise-500: transparent, // TODO: update this value when it got defined on the designs
    turquoise-600: transparent, // TODO: update this value when it got defined on the designs
    // violet
    violet-50: hsla(250, 100%, 95%, 1),
    violet-100: hsla(250, 100%, 91%, 1),
    violet-200: hsla(250, 100%, 85%, 1),
    violet-300: hsla(249, 68%, 74%, 1),
    violet-400: hsla(249, 56%, 66%, 1),
    violet-500: hsla(250, 52%, 58%, 1),
    // snow
    snow-50: hsla(0, 0%, 100%, 1),
    snow-100: hsla(0, 0%, 97%, 1),
    snow-200: hsla(0, 3%, 94%, 1),
    snow-300: hsla(0, 0%, 90%, 1),
    snow-400: hsla(0, 1%, 88%, 1),
    snow-500: hsla(0, 0%, 81%, 1),
);

@function color($color) {
    @return map.get($colors, $color);
}

@each $name, $color in $colors {
    .color.#{$name} {
        color: $color;
    }
}

/* =============================================
=              Background Colors              =
============================================= */
$background-colors: (
    ocean: (
        color: color(ocean-400),
        contrast-color: color(snow-50),
    ),
    ocean-light: (
        color: color(ocean-200),
        contrast-color: color(snow-50),
    ),
    'green': (
        color: color(green-400),
        contrast-color: color(snow-50),
    ),
    green-light: (
        color: color(green-200),
        contrast-color: color(snow-50),
    ),
    'red': (
        color: color(red-400),
        contrast-color: color(snow-50),
    ),
    red-light: (
        color: color(red-200),
        contrast-color: color(snow-50),
    ),
    'orange': (
        color: color(orange-400),
        contrast-color: color(snow-50),
    ),
    orange-light: (
        color: color(orange-200),
        contrast-color: color(snow-50),
    ),
    space: (
        color: color(space-400),
        contrast-color: color(snow-50),
    ),
    space-light: (
        color: color(space-200),
        contrast-color: color(space-400),
    ),
    gray-light: (
        color: color(snow-200),
        contrast-color: color(space-400),
    ),
    'violet': (
        color: color(violet-400),
        contrast-color: color(snow-50),
    ),
    violet-light: (
        color: color(violet-200),
        contrast-color: color(space-400),
    ),
    'yellow': (
        color: color(yellow-400),
        contrast-color: color(snow-50),
    ),
    yellow-light: (
        color: color(yellow-200),
        contrast-color: color(space-400),
    ),
);

@function get-background-color($color-name) {
    @return map.get($background-colors, $color-name);
}

@mixin background-color($name) {
    $colors: get-background-color($name);

    background-color: map.get($colors, color);
    color: map.get($colors, contrast-color);
}

@each $name in map-keys($background-colors) {
    .background-color.#{$name} {
        @include background-color($name);
    }
}
