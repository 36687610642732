@use 'sass:map';
@use 'sass:math';
@use 'misc' as *;

$typography-html-font-size: 14px;
$typography-font-size: 14px;
/* stylelint-disable-next-line max-line-length */
$typography-font-family: 'Open Sans', system-ui, -apple-system, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Cantarell', 'Helvetica Neue';
$typography-font-weights: (
    light: 300,
    normal: 400,
    semi-bold: 600,
    bold: 700
);

/* TODO: Having different line-heights causes some UI and spacing issues.
 * They should be consistent. Please investigate this when refactoring/reimplementing the Typography.
*/
$typography-variants: (
    h1: 60px semi-bold 1.36 -0.03em,
    h2: 48px semi-bold 1.5 -0.025,
    h3: 34px semi-bold 1.6 -0.02em,
    h4: 24px semi-bold 1.7 -0.015em,
    h5: 20px semi-bold 1.8 -0.0125em,
    h6: 16px semi-bold 1.9 -0.01em,
    subtitle1: 14px semi-bold 2 0.01em,
    subtitle2: 12px semi-bold 2.15 0.02em,
    subtitle3: 10px semi-bold 2.4 0.04em,
    body1: 14px regular 2 0.01em,
    body2: 12px regular 2.15 0.02em,
    body3: 10px regular 2.4 0.04em,
);

@function font-weight($weight: regular) {
    @return map.get($typography-font-weights, $weight);
}

@function px2rem($value) {
    @return math.div($value, $typography-html-font-size) * math.div($typography-font-size, 14px) * 1rem;
}

@mixin typo-by-values($font-size, $font-weight, $line-height, $letter-spacing, $text-transform: null) {
    @include font-weight($font-weight);

    font-size: px2rem($font-size);
    letter-spacing: $letter-spacing;
    line-height: $line-height;
    text-transform: $text-transform;
}

@mixin font-weight($weight: regular, $important: false) {
    font-weight: font-weight($weight) if-important($important);
}

@mixin typo-old($variant, $includeFontFamily: false) {
    @if $includeFontFamily {
        font-family: $typography-font-family;
    }

    $variant-values: map.get($typography-variants, $variant);

    @include typo-by-values($variant-values...);
}
