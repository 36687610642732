@use 'sass:map';
@use 'misc' as *;

$easing: (
    in-sine: cubic-bezier(0.47, 0, 0.745, 0.715),
    out-sine: cubic-bezier(0.39, 0.575, 0.565, 1),
    in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95),
    in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53),
    out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
    in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955),
    in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19),
    out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1),
    in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1),
);

$transition-duration: (
    shortest: 150ms,
    shorter: 200ms,
    short: 250ms,
    standard: 300ms,
    long: 350ms,
    longer: 400ms,
    longest: 500ms
);

@function ease($name) {
    @return map.get($easing, $name);
}

@mixin transition($properties, $duration: standard, $ease: in-out-sine, $delay: 0ms) {
    $duration-value: map.get($transition-duration, $duration);
    $timing-function: map.get($easing, $ease);

    @if not $duration-value {
        $duration-value: $duration;
    }

    transition: #{$properties} #{$duration-value} #{$timing-function} #{$delay};
}
