@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

/* stylelint-disable no-descending-specificity */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable declaration-property-value-disallowed-list */
$md-radius: theme.get-border-radius(md);

.root {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    vertical-align: middle;

    &.fullWidth {
        width: 100%;
    }

    &.error {
        .inputWrapper {
            border-color: theme.color(red-500);
        }
    }

    &:hover {
        .inputWrapper,
        .inputWrapper .textInput {
            background-color: theme.color(snow-100);
        }

        .lengthCounter {
            background-color: theme.color(snow-200);
        }
    }

    &.disabled {
        .inputWrapper {
            background-color: theme.color(snow-100);
            border-color: theme.color(gray-50);

            .textInput {
                background-color: theme.color(snow-100);
                border-color: theme.color(gray-50);
                color: theme.color(space-200);

                &::placeholder {
                    color: theme.color(space-200);
                }
            }
        }

        .lengthCounter {
            background-color: theme.color(snow-200);
            color: theme.color(space-200);
        }
    }
}

.inputWrapper {
    background-color: theme.color(snow-50);
    border: 2px solid theme.color(gray-100);
    border-radius: $md-radius;
    color: theme.color(space-500);
    height: 40px;
    justify-self: center;
    line-height: normal;
    overflow: hidden;
    position: relative;

    &.hasHeader {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        &:focus,
        &:focus-visible,
        &:focus-within, {
            border-top-left-radius: $md-radius;
            border-top-right-radius: $md-radius;
        }
    }

    &.small {
        height: 32px;
    }

    &:focus,
    &.focused {
        border-color: theme.color(ocean-400);
    }

    &.textAreaWrapper {
        height: 150px;

        &.small {
            height: 100px;
        }

        &:not(.noMinWidth) {
            min-width: 420px;
        }
    }

    .textInput {
        @include theme.typo(body3);

        border: none;
        color: theme.color(space-500);
        display: block;
        height: 100%;
        line-height: normal;
        outline: none;
        padding: theme.spacing(1);
        position: relative;
        width: 100%;

        &.textCenter {
            text-align: center;
        }

        &::placeholder {
            color: theme.color(space-300);
            user-select: none;
        }
    }

    .textArea {
        resize: none;
    }

    .lengthCounter {
        @include theme.typo(info3);

        background-color: theme.color(snow-100);
        border-radius: theme.get-border-radius(pill);
        bottom: 8px;
        color: theme.color(space-400);
        line-height: normal;
        padding: theme.spacing(0.5);
        position: absolute;
        right: 8px;
        user-select: none;
    }
}

.errorMessageWrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-top: theme.spacing(0.25);
    text-align: left;

    .errorMessage {
        @include theme.typo(info2);

        color: theme.color(red-400);
        display: inline-block;
        line-height: normal;
        margin-left: theme.spacing(0.25);
        margin-top: theme.spacing(0.25);
    }
}

.headerWrapper {
    border: 2px solid theme.color(gray-100);
    border-bottom: none;
    border-radius: $md-radius $md-radius 0 0;
}

.clearHover:hover svg {
    color: theme.color(space-300);
}
