@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.horizontalMargin {
    margin-right: theme.spacing(2);

    &.dense,
    &.ghost {
        margin-right: theme.spacing(1);
    }
}

.verticalMargin {
    margin-bottom: theme.spacing(2);

    &.dense,
    &.ghost {
        margin-bottom: theme.spacing(1);
    }
}

.lastItem {
    margin: 0;
}
