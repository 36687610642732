@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    @include theme.reset-button();
    @include theme.typo(body3);

    background-color: theme.color(snow-100);
    border-radius: theme.get-border-radius(md);
    gap: theme.spacing(0.5);
    height: 40px;
    min-width: 73px;
    padding: theme.spacing(0, 1.5);

    &,
    svg {
        $color: theme.color(space-400);

        color: $color;

        .loadingDots {
            background-color: $color;
        }
    }

    &:disabled {
        cursor: default;

        * {
            cursor: default;
        }
    }

    &.small {
        height: 32px;
    }

    &.fullWidth {
        width: 100% !important;
    }

    &.primary {
        $color: theme.color(snow-50);

        background-color: theme.color(ocean-500);

        &,
        svg {
            color: $color;
        }

        .loadingDots {
            background-color: $color;
        }
    }

    &.secondary {
        $color: theme.color(ocean-500);

        background-color: theme.color(ocean-200);

        &,
        svg {
            color: $color;
        }

        .loadingDots {
            background-color: $color;
        }
    }

    &.ghost {
        $color: theme.color(ocean-500);

        background-color: transparent;

        &,
        svg {
            color: $color;
        }

        .loadingDots {
            background-color: $color;
        }
    }

    &.outlined {
        $color: theme.color(gray-400);

        background-color: theme.color(gray-50);
        border: 2px solid theme.color(gray-300);

        &,
        svg {
            color: $color;
        }

        .loadingDots {
            background-color: $color;
        }
    }

    &.error {
        $color: theme.color(snow-50);

        background-color: theme.color(red-500);

        &,
        svg {
            color: $color;
        }

        .loadingDots {
            background-color: $color;
        }
    }

    &.warning {
        $color: theme.color(space-500);

        background-color: theme.color(yellow-400);

        &,
        svg {
            color: $color;
        }

        .loadingDots {
            background-color: $color;
        }
    }

    &.errorLight {
        $color: theme.color(red-500);

        background-color: theme.color(red-100);

        &,
        svg {
            color: $color;
        }

        .loadingDots {
            background-color: $color;
        }
    }

    &.warningLight {
        $color: theme.color(space-500);

        background-color: theme.color(yellow-50);

        &,
        svg {
            color: $color;
        }

        .loadingDots {
            background-color: $color;
        }
    }

    &.disabled svg {
        color: theme.color(space-200);
    }

    &:hover,
    &.loading {
        background-color: theme.color(snow-200);

        &.primary {
            background-color: theme.color(ocean-400);
        }

        &.secondary {
            $color: theme.color(ocean-400);

            background-color: theme.color(ocean-100);

            &,
            svg {
                color: $color;
            }

            .loadingDots {
                background-color: $color;
            }
        }

        &.ghost {
            background-color: theme.color(snow-200);
        }

        &.outlined {
            $color: theme.color(gray-400);

            background-color: theme.color(gray-100);
            border: 2px solid theme.color(gray-300);

            &,
            svg {
                color: $color;
            }

            .loadingDots {
                background-color: $color;
            }
        }

        &.error {
            background-color: theme.color(red-300);
        }

        &.warning {
            background-color: theme.color(yellow-200);
        }

        &.errorLight {
            background-color: theme.color(red-200);
        }

        &.warningLight {
            background-color: theme.color(yellow-100);
        }
    }

    &:active {
        background-color: theme.color(gray-100);

        &.primary {
            background-color: theme.color(ocean-500);
        }

        &.secondary {
            $color: theme.color(ocean-500);

            background-color: theme.color(ocean-200);

            &,
            svg {
                color: $color;
            }

            .loadingDots {
                background-color: $color;
            }
        }

        &.ghost {
            background-color: theme.color(snow-200);
        }

        &.error {
            background-color: theme.color(red-500);
        }

        &.warning {
            background-color: theme.color(yellow-400);
        }

        &.errorLight {
            background-color: theme.color(red-100);
        }

        &.warningLight {
            background-color: theme.color(yellow-50);
        }
    }

    &.disabled {
        background-color: theme.color(snow-100);
        color: theme.color(space-200);
        cursor: unset;

        &.primary {
            $color: theme.color(gray-300);

            background-color: theme.color(gray-50);

            &,
            svg {
                color: $color;
            }

            .loadingDots {
                background-color: $color;
            }
        }

        &.secondary {
            $color: theme.color(ocean-300);

            background-color: theme.color(ocean-50);

            &,
            svg {
                color: $color;
            }

            .loadingDots {
                background-color: $color;
            }
        }

        &.ghost {
            $color: theme.color(ocean-300);

            background-color: transparent;

            &,
            svg {
                color: $color;
            }

            .loadingDots {
                background-color: $color;
            }
        }

        &.error {
            $color: theme.color(snow-50);

            background-color: theme.color(red-100);

            &,
            svg {
                color: $color;
            }

            .loadingDots {
                background-color: $color;
            }
        }

        &.warning {
            $color: theme.color(space-200);

            background-color: theme.color(yellow-100);

            &,
            svg {
                color: $color;
            }

            .loadingDots {
                background-color: $color;
            }
        }

        &.errorLight {
            $color: theme.color(red-200);

            background-color: theme.color(red-100);

            &,
            svg {
                color: $color;
            }

            .loadingDots {
                background-color: $color;
            }
        }

        &.warningLight {
            $color: theme.color(space-200);

            background-color: theme.color(yellow-50);

            &,
            svg {
                color: $color;
            }

            .loadingDots {
                background-color: $color;
            }
        }
    }
}
