@use '~$app-web/ui-kit/styles/theme';

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: theme.spacing(2);
}

.unitsWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        background-color: rgb(229, 229, 229);
        border: 1px solid rgb(127, 127, 127);
        color: #000;
        cursor: pointer;
        font-weight: 600;
        outline: none;
        padding-block: 4px;
        width: 64px;

        &:first-of-type {
            border-radius: 4px 0 0 4px;
            border-right: none;
        }

        &:last-of-type {
            border-radius: 0 4px 4px 0;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &:disabled {
            background-color: #000;
            color: #FFF;

            &:hover {
                background-color: #3C3C3C;
            }
        }
    }
}
