@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    display: inline-flex;

    [data-radix-popper-content-wrapper] {
        min-width: inherit !important;
    }
}
