@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.root {
    --divider-thickness: 2px;

    background-color: theme.color(gray-100);

    &.narrow {
        --divider-thickness: 1px;
    }

    &.thick {
        --divider-thickness: 4px;
    }

    &.vertical {
        height: 100%;
        margin: theme.spacing(0, 1);
        width: var(--divider-thickness);
    }

    &:not(.vertical) {
        height: var(--divider-thickness);
        margin: theme.spacing(1, 0);
        width: 100%;

        &.loose {
            margin: theme.spacing(2, 0);
        }

        &.dense {
            margin-top: theme.spacing(0.5);
        }

        &.noMargin {
            margin: 0;
        }
    }
}
