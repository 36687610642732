@use 'theme';

// Set html font size
html {
    font-size: theme.$typography-html-font-size;
}

/* Make html, body and root full height and with no overflow scroll bar */
html,
body,
#root {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

/*
    Change scrollbar appearance in webkit
*/
body {
    @include theme.scrollbar();

    /* TODO: Radix-UI uses a portal for its tooltip which we need to set a z-index for.
     * We've styled it here since it's portaled into the "Body".
     * This is a quick fix for now, but needs to be removed and somehow moved to the component styles itself.
    */
    [data-radix-popper-content-wrapper] {
        z-index: theme.get-z-index(data-radix-popper-content-wrapper) !important;
    }
}
