@use '~$app-web/ui-kit/styles/theme';

/* TODO: The font-sizes used here are based on "em", but they should be based on "rem"
 * to make all icons with the same "size" prop, the same size.
 * The size shouldn't be based on the font-size of container. Also, we can't use width with FA icons.
 */

.root {
    @include theme.reset-button();

    align-items: center;
    display: inline-flex;
    justify-content: center;

    &.xs {
        height: 18px;
        max-height: 18px;
        max-width: 18px;
        width: 18px;

        > svg {
            font-size: theme.px2rem(11px);
            width: 14px;
        }
    }

    &.sm {
        height: 20px;
        max-height: 20px;
        max-width: 20px;
        width: 20px;

        > svg {
            font-size: theme.px2rem(13px);
            width: 16px;
        }
    }

    &.md {
        height: 24px;
        max-height: 24px;
        max-width: 24px;
        width: 24px;

        > svg {
            font-size: theme.px2rem(16px);
            width: 20px;
        }
    }

    &.lg {
        height: 30px;
        max-height: 30px;
        max-width: 30px;
        width: 30px;

        > svg {
            font-size: theme.px2rem(21px);
            width: 26px;
        }
    }
}

.error {
    color: theme.color(red-400);
}

.warning {
    color: theme.color(yellow-400);
}

.success {
    color: theme.color(green-400);
}

.primary {
    color: theme.color(ocean-400);
}
