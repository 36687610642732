@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src:
        url('./fonts/Inter-Regular.woff2?v=3.19') format('woff2'),
        url('./fonts/Inter-Regular.woff?v=3.19') format('woff');
}
@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src:
        url('./fonts/Inter-Italic.woff2?v=3.19') format('woff2'),
        url('./fonts/Inter-Italic.woff?v=3.19') format('woff');
}
@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src:
        url('./fonts/Inter-Medium.woff2?v=3.19') format('woff2'),
        url('./fonts/Inter-Medium.woff?v=3.19') format('woff');
}
@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    src:
        url('./fonts/Inter-MediumItalic.woff2?v=3.19') format('woff2'),
        url('./fonts/Inter-MediumItalic.woff?v=3.19') format('woff');
}
@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src:
        url('./fonts/Inter-Bold.woff2?v=3.19') format('woff2'),
        url('./fonts/Inter-Bold.woff?v=3.19') format('woff');
}
@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 700;
    src:
        url('./fonts/Inter-BoldItalic.woff2?v=3.19') format('woff2'),
        url('./fonts/Inter-BoldItalic.woff?v=3.19') format('woff');
}
